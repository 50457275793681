module.exports = {
  __depends__: [
    require(335),
    require(398),
    require(374),
    require(392),
    require(327),
    require(382)
  ],
  __init__: [ 'move', 'movePreview' ],
  move: [ 'type', require(369) ],
  movePreview: [ 'type', require(370) ]
};
