module.exports = {
  __init__: [
    'appendBehavior',
    'copyPasteBehavior',
    'createBoundaryEventBehavior',
    'createDataObjectBehavior',
    'dropOnFlowBehavior',
    'createParticipantBehavior',
    'dataInputAssociationBehavior',
    'deleteLaneBehavior',
    'importDockingFix',
    'labelBehavior',
    'modelingFeedback',
    'removeParticipantBehavior',
    'replaceConnectionBehavior',
    'replaceElementBehaviour',
    'resizeLaneBehavior',
    'unsetDefaultFlowBehavior',
    'updateFlowNodeRefsBehavior',
    'removeElementBehavior',
    'unclaimIdBehavior',
    'toggleElementCollapseBehaviour'
  ],
  appendBehavior: [ 'type', require(32) ],
  copyPasteBehavior: [ 'type', require(33) ],
  createBoundaryEventBehavior: [ 'type', require(34) ],
  createDataObjectBehavior: [ 'type', require(35) ],
  dropOnFlowBehavior: [ 'type', require(39) ],
  createParticipantBehavior: [ 'type', require(36) ],
  dataInputAssociationBehavior: [ 'type', require(37) ],
  deleteLaneBehavior: [ 'type', require(38) ],
  importDockingFix: [ 'type', require(40) ],
  labelBehavior: [ 'type', require(41) ],
  modelingFeedback: [ 'type', require(42) ],
  removeParticipantBehavior: [ 'type', require(44) ],
  replaceConnectionBehavior: [ 'type', require(45) ],
  replaceElementBehaviour: [ 'type', require(46) ],
  resizeLaneBehavior: [ 'type', require(47) ],
  unsetDefaultFlowBehavior: [ 'type', require(50) ],
  updateFlowNodeRefsBehavior: [ 'type', require(51) ],
  removeElementBehavior: [ 'type', require(43) ],
  unclaimIdBehavior: [ 'type', require(49) ],
  toggleElementCollapseBehaviour : [ 'type', require(48) ]
};
