module.exports = {
  __depends__: [
    require(392),
    require(327),
    require(382)
  ],
  __init__: [ 'resize', 'resizePreview', 'resizeHandles' ],
  resize: [ 'type', require(385) ],
  resizePreview: [ 'type', require(387) ],
  resizeHandles: [ 'type', require(386) ]
};
