module.exports = {
  __depends__: [
    require(298),
    require(329),
    require(333),
    require(341),
    require(405),
    require(20),
    require(14),
    require(16),
    require(83),
    require(65)
  ],
  editorActions: [ 'type', require(17) ]
};
