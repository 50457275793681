'use strict';

var assign = require(237);

var BpmnModdle = require(99);

var packages = {
  bpmn: require(102),
  bpmndi: require(103),
  dc: require(104),
  di: require(105),
  bioc: require(101)
};

module.exports = function(additionalPackages, options) {
  return new BpmnModdle(assign({}, packages, additionalPackages), options);
};
