module.exports = {
  __init__: [ 'modeling', 'bpmnUpdater' ],
  __depends__: [
    require(52),
    require(26),
    require(81),
    require(69),
    require(79),
    require(286),
    require(409),
    require(339),
    require(300),
    require(398),
    require(312),
    require(405)
  ],
  bpmnFactory: [ 'type', require(27) ],
  bpmnUpdater: [ 'type', require(29) ],
  elementFactory: [ 'type', require(30) ],
  modeling: [ 'type', require(31) ],
  layouter: [ 'type', require(28) ],
  connectionDocking: [ 'type', require(416) ]
};
