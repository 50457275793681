module.exports = {
  __depends__: [
    require(378),
    require(322),
    require(405),
    require(341),
    require(333),
    require(413),
    require(20)
  ],
  __init__: [ 'paletteProvider' ],
  paletteProvider: [ 'type', require(70) ]
};
