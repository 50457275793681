'use strict';

module.exports = {
  __init__: ['spaceToolPreview'],
  __depends__: [
    require(327),
    require(392),
    require(407),
    require(382)
  ],
  spaceTool: ['type', require(402)],
  spaceToolPreview: ['type', require(403) ]
};
